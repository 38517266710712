import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from '~/util/i18next';
import { AboutKyc } from '~/components/organisms/AboutKyc';
import { useGetMeInfo, usePatchMeInfo } from '~/api/hooks/useMeInfo';
import { ProgressLoading } from '~/components/atoms/ProgressLoading';

interface PopUpDisclaimerData {
  showPopUp: () => void;
  hidePopUp: () => void;
}

export const PopUpDisclaimerContext = createContext<PopUpDisclaimerData>({} as PopUpDisclaimerData);

export function PopUpDisclaimerProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { meInfo, isLoading } = useGetMeInfo();
  const { patchMeInfo } = usePatchMeInfo();

  const showPopUp = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const hidePopUp = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    if (meInfo && meInfo.confirmedOn) {
      if (new Date(meInfo.confirmedOn).getTime() < new Date(t('disclaimer.datekey')).getTime()) {
        showPopUp();
      }
    }
  }, [meInfo, showPopUp, t]);

  function onConfirmAboutKycModal(isChecked: boolean) {
    if (isChecked) {
      const dateNow = new Date();
      const dateNowUtc = Date.UTC(
        dateNow.getUTCFullYear(),
        dateNow.getUTCMonth(),
        dateNow.getUTCDate(),
        dateNow.getUTCHours(),
        dateNow.getUTCMinutes(),
        dateNow.getUTCSeconds(),
      );
      patchMeInfo({
        confirmedOn: new Date(dateNowUtc).toISOString(),
      });
    }
    hidePopUp();
  }

  if (isLoading || !meInfo) {
    return <ProgressLoading />;
  }

  return (
    <PopUpDisclaimerContext.Provider
      value={{
        showPopUp,
        hidePopUp,
      }}
    >
      <AboutKyc isOpen={isOpen} handleClose={hidePopUp} onConfirm={onConfirmAboutKycModal} />
      {children}
    </PopUpDisclaimerContext.Provider>
  );
}

export const usePopUpDisclaimer = () => useContext(PopUpDisclaimerContext);
