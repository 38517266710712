import { Dialog, FormControlLabel, Link, Stack, SvgIcon, Typography, useMediaQuery } from '@mui/material';

import { theme } from '~/theme';
import CheckIcon from '~/components/icons/check-circle-thin.svg';
import WarningIcon from '~/components/icons/warning-octagon.svg';
import { CheckBox } from '~/components/atoms/CheckBox';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { moreAMLPreventionLink, moreNewsboardLink } from '~/util/consts/links';
import { Trans } from 'react-i18next';
import { useTranslation } from '~/util/i18next';

interface AboutKycProps {
  isOpen: boolean;
  handleClose: () => void;
  onConfirm: (isChecked: boolean) => void;
}

export function AboutKyc({ handleClose, isOpen, onConfirm }: AboutKycProps) {
  const [isChecked, setIsChecked] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      disableEscapeKeyDown
      PaperProps={{ sx: { maxWidth: '900px', margin: { xs: 0 } } }}
    >
      <Stack
        gap={3}
        sx={{
          backgroundColor: 'white',
          color: theme.palette.text.primary,
          width: 'auto',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
          padding: '40px',
        }}
      >
        <Typography variant="h3" fontSize={20} fontWeight={'bold'}>
          {t('disclaimer.headline')}
        </Typography>
        <Typography variant="body3" color={theme.palette.text.secondary}>
          <Stack fontSize={16} gap={2}>
            {t('disclaimer.textbullets')
              .split('\n')
              .map((item: string, index: number) => (
                <Stack key={item} alignItems={isDesktop ? 'center' : 'flex-start'} direction={'row'} gap={1}>
                  {index !== 0 && <SvgIcon component={CheckIcon} sx={{ color: theme.palette.special.green1 }} />}
                  <Typography variant="body3">{item}</Typography>
                </Stack>
              ))}
          </Stack>
        </Typography>
        <Stack direction={'row'} bgcolor={theme.palette.transparent.red8} gap={1} p={2} borderRadius={1}>
          <SvgIcon
            component={WarningIcon}
            viewBox="0 0 36 37"
            sx={{ fontSize: 36, color: theme.palette.primary.dark }}
          />
          <Typography variant="body3" color={theme.palette.text.secondary} fontSize={14} lineHeight={'24.5px'}>
            {t('disclaimer.warningtext')}
          </Typography>
        </Stack>
        <Typography variant="body3" color={theme.palette.text.secondary} fontSize={14} lineHeight={'24.5px'}>
          <Trans
            i18nKey="disclaimer.outrotext"
            components={{
              moreNewsboardLink: (
                <Link
                  href={moreNewsboardLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: 'currentcolor',
                  }}
                />
              ),
              moreAMLpreventionLink: (
                <Link
                  href={moreAMLPreventionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: 'currentcolor',
                  }}
                />
              ),
            }}
          />
        </Typography>

        <Stack direction={'row'} alignItems={'center'} gap={0.5}>
          <FormControlLabel
            control={<CheckBox onChangeCheckbox={() => setIsChecked(!isChecked)} />}
            label={
              <Typography variant="body3" color={theme.palette.text.secondary} fontSize={14} lineHeight={'24.5px'}>
                {t('disclaimer.checkboxtext')}
              </Typography>
            }
          />
        </Stack>
        <Button
          color="primary"
          sx={{ alignSelf: 'flex-start', px: 4 }}
          variant="contained"
          onClick={() => {
            onConfirm(isChecked);
          }}
        >
          {t('disclaimer.button.confirm')}
        </Button>
      </Stack>
    </Dialog>
  );
}
