import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import { SUPPORTED_LANGUAGES, useTranslation } from '~/util/i18next';
import { ErrorPage } from '~/components/organisms/ErrorPage';
import { useMemo } from 'react';
import { pages } from '~/pages/routes';
import { KycFrame } from '~/components/organisms/Main';

function useRouter() {
  const { t } = useTranslation();
  return useMemo(
    () =>
      createBrowserRouter([
        {
          element: <KycFrame />,
          errorElement: (
            <ErrorPage
              title={t('error.unknownError.title')}
              message={t('error.unknownError.message')}
              buttonText={t('error.unknownError.button')}
              onClick={() => location.reload()}
            />
          ),
          children: createLocaleRoutes(pages),
        },
      ]),
    [t],
  );
}

function createLocaleRoutes(routes: RouteObject[]): RouteObject[] {
  return [
    ...SUPPORTED_LANGUAGES.map((lang) => ({
      path: lang,
      children: routes,
    })),
    ...routes,
  ];
}

export function KycRouter() {
  const router = useRouter();
  return <RouterProvider router={router} />;
}
