import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect, useRef, useState } from 'react';
import { ErrorPage } from '~/components/organisms/ErrorPage';
import { useTranslation } from '~/util/i18next';
import { KeycloakContext, ParsedJwtToken, setupKeycloak } from '@ev/eva-container-api';

const keycloak = setupKeycloak({
  url: import.meta.env.VITE_KEYCLOAK_URL,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
});

export function KeycloakProvider({ children }: React.PropsWithChildren) {
  const authenticating = useRef(false);
  const [hasError, setHasError] = useState(false);
  const [parsedToken, setParsedToken] = useState<ParsedJwtToken>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!authenticating.current) {
      authenticating.current = true;
      keycloak
        .init({ onLoad: 'login-required', checkLoginIframe: false })
        .then(() => {
          setParsedToken(keycloak.tokenParsed as ParsedJwtToken);
        })
        .catch(() => {
          setHasError(true);
        });

      keycloak.onAuthError = () => {
        setHasError(true);
      };
      keycloak.onAuthRefreshSuccess = () => {
        setParsedToken(keycloak.tokenParsed as ParsedJwtToken);
      };
    }
  }, []);

  if (hasError) {
    return (
      <ErrorPage
        title={t('error.authError.title')}
        message={t('error.authError.message')}
        buttonText={t('error.authError.button')}
        onClick={() => location.reload()}
      />
    );
  }

  if (!parsedToken) {
    return <LinearProgress />;
  }

  return <KeycloakContext.Provider value={parsedToken}>{children}</KeycloakContext.Provider>;
}
