import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Avatar } from '~/components/atoms/Avatar';
import { theme } from '~/theme';
import { useJwtToken } from '@ev/eva-container-api';

interface IProfileInfoProps {
  firstName: string;
  lastName: string;
}

export const ProfileInfo = ({ firstName, lastName }: IProfileInfoProps) => {
  const { jwtToken } = useJwtToken();

  return (
    <Stack direction={'row'} alignItems={'center'} gap={1}>
      <Avatar firstName={firstName} lastName={lastName} />
      <Stack alignItems={'flex-start'}>
        <Typography variant="body3">{`${firstName} ${lastName}`}</Typography>
        <Typography variant="body4" color={theme.palette.text.secondary}>
          {jwtToken.email}
        </Typography>
      </Stack>
    </Stack>
  );
};
