import { MouseEvent, useState } from 'react';

import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import ArrowIcon from '~/components/icons/arrow-down.svg';
import GlobeIcon from '~/components/icons/globe.svg';
import QuestionIcon from '~/components/icons/question.svg';
import LogoutIcon from '~/components/icons/logout.svg';
import { MobileMenuItem } from './MobileMenuItem';
import { useTranslation } from '~/util/i18next';
import { LanguageDropdown } from '~/components/molecules/LanguageDropdown';
import { SupportDropdown } from '~/components/molecules/SupportDropdown';
import { ProfileMenu } from '~/components/molecules/ProfileMenu';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { useSidebarItems } from '~/components/organisms/Sidebar/useSidebarItems';
import { signOut } from '@ev/eva-container-api';

interface IMobileMenuProps {
  handleRedirect?: () => void;
}

export const MobileMenu = ({ handleRedirect }: IMobileMenuProps) => {
  const [languageAnchorEl, setLanguageAnchorEl] = useState<HTMLElement | undefined>(undefined);
  const [supportAnchorEl, setSupportAnchorEl] = useState<HTMLElement | undefined>(undefined);

  const handleClose = () => {
    setLanguageAnchorEl(undefined);
    setSupportAnchorEl(undefined);
  };

  const openLanguageSelector = (e: MouseEvent<HTMLElement>) => {
    setLanguageAnchorEl(e.currentTarget);
  };

  const openSupportDropDown = (e: MouseEvent<HTMLElement>) => {
    setSupportAnchorEl(e.currentTarget);
  };
  const { t } = useTranslation();
  const sidebarItems = useSidebarItems();

  return (
    <Stack padding={2} gap={1}>
      <Paper variant="outlined">
        <List sx={{ padding: 2 }}>
          {sidebarItems.map(({ href, label, icon }) => (
            <MobileMenuItem key={label} href={href} label={label} icon={icon} onClick={handleRedirect} />
          ))}
        </List>
      </Paper>

      <Paper variant="outlined">
        <List sx={{ padding: 2 }}>
          <MobileMenuItem
            label={t('general.language')}
            icon={GlobeIcon}
            endIcon={<ArrowIcon />}
            onClick={openLanguageSelector}
          />

          <MobileMenuItem
            label={t('general.support')}
            icon={QuestionIcon}
            endIcon={<ArrowIcon />}
            onClick={openSupportDropDown}
          />
        </List>
      </Paper>

      <Paper variant="outlined">
        <List sx={{ padding: 2, display: ' flex', flexDirection: 'column', gap: 2 }}>
          <ListItem sx={{ py: 1.5, px: 1 }} disablePadding>
            <ProfileMenu />
          </ListItem>
          <Divider />
          <MobileMenuItem
            label={t('general.logout')}
            icon={LogoutIcon}
            onClick={() => {
              signOut();
            }}
          />
        </List>
      </Paper>

      <LanguageDropdown anchorEl={languageAnchorEl} onClose={handleClose} />
      <SupportDropdown anchorEl={supportAnchorEl} onClose={handleClose} />
    </Stack>
  );
};
