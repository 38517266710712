import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import { SwipeableDrawerContent } from '~/components/molecules/SwipeableDrawerContent';

import { theme } from '~/theme';

export interface DropdownMenuItem {
  key: string;
  label: string;
  icon?: React.ReactNode;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  color?: string;
  testId?: string;
}

export interface IMenuProps {
  title?: string;
  items?: DropdownMenuItem[];
  onClick?: () => void;
  onClose?: () => void;
  anchorEl?: HTMLElement;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  children: ReactNode;
}

export const DropdownMenu = ({
  children,
  title,
  items,
  onClick,
  onClose,
  anchorEl,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
}: IMenuProps) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const open = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const list = useMemo(
    () => (
      <List onClick={onClick} sx={{ paddingBlock: 0.5 }}>
        {items?.map((item) => (
          <ListItem key={item.key} disablePadding>
            <ListItemButton
              onClick={(e) => {
                handleClose();
                item.onClick(e);
              }}
              data-testid={item.testId}
            >
              {item.icon && <ListItemIcon sx={{ color: item.color }}>{item.icon}</ListItemIcon>}
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{item.label}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    ),

    [items, onClick, handleClose],
  );

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        open={open && isDesktop}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {items ? list : children}
      </Popover>
      <SwipeableDrawer open={open && !isDesktop} anchor="bottom" onClose={handleClose} onOpen={() => {}}>
        <SwipeableDrawerContent onClose={handleClose} title={title}>
          <Paper>{items ? list : children}</Paper>
        </SwipeableDrawerContent>
      </SwipeableDrawer>
    </>
  );
};
